/**
 * Footer
 */

import React, { Fragment } from 'react';
import { StaticQuery, graphql } from 'gatsby';
import get from 'lodash/get';
import { ContentParser } from '@components';

const Footer = () => (
  <StaticQuery
    query={graphql`
      query {
        wordpressAcfOptions {
          options {
            footer_content
          }
        }
      }
    `}
    render={data => {
      const content = get(data, 'wordpressAcfOptions.options.footer_content', null);
      
      if (!content) {
        return null;
      }

      return (
        <Fragment>
          <div id="content-end" />
          <div className="spacer--m" />
          <ContentParser className="page__block page__block--text" content={content} />
        </Fragment>
      )
    }}
  />
);

export default Footer;
