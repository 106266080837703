/**
 * Video
 */

 /* eslint-disable jsx-a11y/media-has-caption */

import React, { Component, createRef } from 'react';
import Hls from 'hls.js';

class Video extends Component {
  constructor(props) {
    super(props);

    this.videoRef = createRef(null);

    this.state = {
      initialized: false,
    };
  }

  componentDidUpdate(prevProps) {
    const { inView } = this.props;
    const { initialized } = this.state;
    // Init HLS if video is in view
    if (prevProps.inView !== inView && inView) {
      if (!initialized) {
        this.initializeHLS();
      } else {
        // Already initialized.. continue playing
        this.playVideo();
      }
    } else if (prevProps.inView === true && inView === false) {
      if (initialized) {
        this.pauseVideo();
      }
    }
  }

  componentWillUnmount() {
    this.destroyVideoInstance();
  }

  pauseVideo = () => {
    const video = this.videoRef.current;
    if (video) {
      video.pause();
    }

    if (this.hls) {
      this.hls.stopLoad();
    }
  }

  playVideo = () => {
    const video = this.videoRef.current;

    if (video) {
      video.play();
    }

    if (this.hls) {
      this.hls.stopLoad();
    }
  }

  destroyVideoInstance = () => {
    const video = this.videoRef.current;

    if (video) {
      video.pause();
      video.removeAttribute('src');
      video.load();
    }

    if (this.hls) {
      this.hls.detachMedia();
      this.hls.destroy();
    }
  }

  getVideoFile = file => {
    const { path } = this.props;
    const storageUrl = process.env.GATSBY_STORAGE_URL;
    return `${storageUrl}${path}/${file}`;
  }

  initializeHLS = () => {
    const { autoPlay } = this.props;
    this.hls = new Hls();
    this.useHLS = Hls.isSupported();

    const m3u8 = this.getVideoFile('index.m3u8');
    const video = this.videoRef.current;

    if(this.useHLS) {
      this.hls.loadSource(m3u8);
      this.hls.attachMedia(video);

      this.hls.on(Hls.Events.MANIFEST_PARSED, () => {
        if (autoPlay) {
          this.setState({
            initialized: true,
          });

          video.play();
        }
      });
    }
   // hls.js is not supported on platforms that do not have Media Source Extensions (MSE) enabled.
   // When the browser has built-in HLS support (check using `canPlayType`), we can provide an HLS manifest (i.e. .m3u8 URL) directly to the video element throught the `src` property.
   // This is using the built-in support of the plain video element, without using hls.js.
    else if (video.canPlayType('application/vnd.apple.mpegurl')) {
      video.src = m3u8;
      video.addEventListener('canplay', () => {
        if (autoPlay) {
          this.setState({
            initialized: true,
          });

          video.play();
        }
      });
    }
  }

  render() {
    const { autoPlay, inView, className, poster, loop, preload, muted } = this.props;
    const hlsVideo = this.getVideoFile('index.m3u8');
    const mp4Video = this.getVideoFile('progressive.mp4');

    return (
      <video
        autoPlay={autoPlay && inView} 
        className={className}
        loop={loop}
        muted={muted}
        poster={poster}
        preload={preload}
        ref={this.videoRef}
        playsInline
      >
        <source src={hlsVideo} type="application/vnd.apple.mpegurl" />
        <source src={mp4Video} type='video/mp4' />
      </video>
    )
  }
}

Video.defaultProps = {
  // To-do: Make this component autodetect this
  inView: true,
};

export default Video;
