/**
* PageLoadManager
* Handles fade-in transitions on initial page load
*/

import React, { useEffect, useState } from 'react';
const debug = false;

const PageLoadManager = ({ children }) => {
  const [eventListenersAdded, setEventListenersAdded] = useState(false);
  const [loaded, setLoaded] = useState(false);

  const skipLoadingTransitionFor = (className, callback) => {
    if (document) {
      const el = document.querySelector('.' + className); /*eslint-disable-line*/
  
      if (el) {
        el.style.opacity = 0.999;
        el.style.transform = "translatey(0.000001em)";
        el.style.transition = "opacity 1s linear, transform 1s ease-out";
        el.classList.remove(className);
    
        if (typeof callback === 'function') {
          callback(el);
        }
      }
    }
  }

  const skipLoadingAnimations = () => {
    if (debug) {
      console.warn('PageLoadManager: skip animations');
    }
    const headerEl  = document.getElementById('site__header');
    const pageBodyEl   = document.querySelector(".fade-in-page__body");

    if (!loaded) {
      skipLoadingTransitionFor('fade-in-eyebrow');
      skipLoadingTransitionFor('fade-in-title');
      skipLoadingTransitionFor('fade-in-subtitle');
      skipLoadingTransitionFor('fade-in-page-header-buttons');
      skipLoadingTransitionFor('fade-in-page-header-image');
      skipLoadingTransitionFor('fade-in-intro', el => {
        const links = el.querySelectorAll("a");
        links.forEach(link => {
          const linkEl = link;
          linkEl.style.borderColor = "#010101";
          linkEl.style.transition = "border-color 1s linear";
        })
      });

      if (headerEl) {
        headerEl.style.opacity = 0.999;
        headerEl.style.transition = "opacity 1s linear";
        headerEl.classList.remove("fade-in-site__header");
      
        if (pageBodyEl) {
          pageBodyEl.style.opacity = 0.999;
          pageBodyEl.style.transition = "opacity 1s linear";
          pageBodyEl.classList.remove("fade-in-page__body");
        }
        // Remove styles after skipping Load Animations, to restore their own transitions
        setTimeout(() => {
          if (headerEl.style.removeProperty) {
              headerEl.style.removeProperty('transition');
              headerEl.style.removeProperty('opacity');
          // For IE9
          } else {
              headerEl.style.removeProperty('transition');
              headerEl.style.removeProperty('opacity');
          }
        }, 2000);
      }

      if (debug) {
        console.warn('PageLoadManager: should unregister events');
      }
      headerEl.removeEventListener('mouseover', skipLoadingAnimations, true);
      window.removeEventListener('scroll', skipLoadingAnimations, true);
      document.removeEventListener('click', skipLoadingAnimations, true);
      document.removeEventListener('keydown', skipLoadingAnimations, true);

      setLoaded(true);
    }
  }

  useEffect(() => {
    if (!eventListenersAdded && !loaded && process.env.NODE_ENV === 'production') {
      if (debug) {
        console.warn('PageLoadManager: register events');
      }
      const headerEl  = document.getElementById('site__header');

      // If user has scrolled the page
      if (window.scrollY > 10) {
        skipLoadingAnimations();
      } 

      if (headerEl) {
        headerEl.addEventListener('mouseover', skipLoadingAnimations, true);
      }
      window.addEventListener('scroll', skipLoadingAnimations, true);
      document.addEventListener('click', skipLoadingAnimations, true);
      document.addEventListener('keydown', skipLoadingAnimations, true);

      setEventListenersAdded(true);
    }

  });

  return children;
};

export default PageLoadManager;