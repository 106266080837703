/**
 * Header
 */

import React, { useState, useRef, useContext } from 'react';
import classnames from 'classnames';
import { Link, useStaticQuery, graphql } from 'gatsby';
import { Location } from '@reach/router'
import useEventListener from '@utils/hooks/useEventListener';
import get from 'lodash/get';
import { AppContext } from '@context';
import throttle from 'lodash/throttle';

const SCROLL_DELTA = 5;

function outerHeightOfElement(el) {
  const style = getComputedStyle(el);
  return el.offsetHeight + parseInt(style.marginTop, 10) + parseInt(style.marginBottom, 10);
}

const Header = () => {
  const { ready: appIsLoaded } = useContext(AppContext);
  const headerRef = useRef(null);
  const [headerHasFocusWithin, setHeaderHasFocusWithin] = useState(false);
  const [showHeader, setShowHeader] = useState(true);
  const [hasScrolled, setHasScrolled] = useState(false);
  const [previousScrollPosition, setPreviousScrollPosition] = useState(0);
  const shouldShowHeader = headerHasFocusWithin || (showHeader && hasScrolled);

  const data = useStaticQuery(graphql`
    query {
      wordpressSiteMetadata {
        name
      }
      allWordpressWpApiMenusMenusItems {
        edges {
          node {
            id
            name
            items {
              order
              title
              url
              object_slug
            }
          }
        }
      }
    }
  `);
  const items = get(data, 'allWordpressWpApiMenusMenusItems.edges[0].node.items') || [];
  const menuItems = items.sort((a, b) => a.order - b.order);

  /**
   * When a child element is focused or blurred
   * we check if the focus is within the header and
   * show or hide the header accordingly
   */
  const onChildFocusOrBlur = () => {
    setTimeout(() => {
      const hasFocusWithin = headerRef.current.contains(document.activeElement);
      setHeaderHasFocusWithin(hasFocusWithin);
    }, 50);
  }

  /**
   * Handle header visibility
   */
  const handleHeaderVisibility = () => {
    const siteHeaderHeight = outerHeightOfElement(headerRef.current);
    const currentScrollPosition = window.pageYOffset || document.documentElement.scrollTop;
    const contentEndTopFromScreenTop = document.getElementById("content-end").getBoundingClientRect().top;

    // Scroll down
    if (
      currentScrollPosition > previousScrollPosition && 
      currentScrollPosition > siteHeaderHeight
    ){
      if (
        contentEndTopFromScreenTop <= (window.innerHeight) && 
        contentEndTopFromScreenTop >= 0
      ) {
        
        if (!showHeader) {
          setShowHeader(true);
        }

      // if bottom is not reached and header is visible
      } else if (showHeader) { 
        setShowHeader(false);
      }

    // Scroll up
    } else if (!showHeader) {
      setShowHeader(true);
    }
  }


  const onScrollHandler = () => {
    const currentScrollPosition = window.pageYOffset || document.documentElement.scrollTop;

    // Only handle header visibility if the user has scrolled more than SCROLL_DELTA
    if(Math.abs(previousScrollPosition - currentScrollPosition) >= SCROLL_DELTA){
      if (!hasScrolled) {
        setHasScrolled(true);
      }

      handleHeaderVisibility();
    }

    // Save previous scroll position
    setPreviousScrollPosition(currentScrollPosition);
  }

  useEventListener('scroll', throttle(onScrollHandler, 250));

  let headerVisibilityClass = null;

  if (hasScrolled || headerHasFocusWithin) {
    if (shouldShowHeader) {
      headerVisibilityClass = 'nav-down';
    } else {
      headerVisibilityClass = 'nav-up';
    }
  }

  return (
    <header ref={headerRef} className={classnames('fade-in-site__header', headerVisibilityClass)} id="site__header">
      <div className="site__header__section-start">
        <Link className="site__name tooltip-trigger" to="/" onFocus={onChildFocusOrBlur} onBlur={onChildFocusOrBlur}>
          {get(data, 'wordpressSiteMetadata.name', 'SAMHÆNG')}
        </Link>
      </div>
      {menuItems.length ? (
        <div className="site__header__section-end">
          <nav className="site__navigation">
            <Location>
              {({ location }) => (
                <ul>
                  { menuItems.map(({ object_slug, title }) => {
                    const url = object_slug === 'home' ? '/' : `/${object_slug}`;
                    const isCurrent = location.pathname.replace(/\/$/, '') === url;

                    return (
                      <li key={object_slug} className={isCurrent ? 'current' : null}>
                        <Link className="tooltip-trigger" to={url} onFocus={onChildFocusOrBlur} onBlur={onChildFocusOrBlur}>
                          {title}
                        </Link>
                      </li>
                    );
                  })}
                </ul>
              )}
            </Location>
          </nav>
        </div>
      ) : null}
    </header>
  )
}

export default Header;
