/**
 * ContentParser
 */

import React, { Component, createRef } from 'react';
import classnames from 'classnames';
import { navigate } from '@reach/router';
import convertWpUrlToPath from '@utils/convertWpUrlToPath';

export default class ContentParser extends Component {
  static defaultProps = {
    element: 'div',
  };

  constructor(props) {
    super(props);

    this.ref = createRef(null);
  }

  componentDidMount() {
    if (this.ref && this.ref.current) {
      this.attachClickHandlerForAnchorTags();
    }
  }
  
  /**
  * Attach click handlers for anchor tags after mount
  * (For replacing the default behaviour with the gatsby router behaviour)
  */
  attachClickHandlerForAnchorTags = () => {
    const el = this.ref.current;
    const links = el.querySelectorAll('a');

    if (!links || !links.length) {
      return;
    }

    Array.from(links)
      // Since we replace the URLs with paths, we can assume that
      // we should overwrite click functionality for all links with a path
      .filter(link => link.outerHTML.indexOf('http') < 0)
      .forEach(link => {
        link.addEventListener('click', (e) => {
          e.preventDefault();
          navigate(link.href);
        }, true);
    });
  }
  
  convertUrlsToPaths = content => content.replace(/href="(.*?)"/g, m => convertWpUrlToPath(m));

  parse = content => {
    if (!content) {
      return null;
    }
    
    let parsed = content;
    parsed = this.convertUrlsToPaths(parsed);

    return parsed;
  };

  render() {
    const { element: Element, content, className, ...rest } = this.props;

    // Content should not be parsed
    if (typeof content !== 'string') {
      return (
        <Element 
          className={className}
          {...rest}
        >
          {content}
        </Element>
      );
    }

    return (
      <Element 
        dangerouslySetInnerHTML={{ __html: this.parse(content) }}
        className={classnames('has-content-parser', className)}
        ref={this.ref}
        {...rest} 
      />
    )
  }
}
