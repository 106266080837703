import React, { Component } from 'react';
import { AppContext } from '@context';
import { PageLoadManager } from '@layout';

// Global CSS
import './filip-code/src/css/style.styl';

// InteractionObserver polyfill (needed for some browsers for gatsby-image to work properly)
export const onClientEntry = async () => {
  if (typeof IntersectionObserver === `undefined`) {
    await import(`intersection-observer`);
  }
}

// Remove Gray Highlight When Tapping Links in Mobile Safari, 
// and then to allow :active styles to work in your CSS on a page in Mobile Safari:
document.addEventListener("touchstart", () => {}, true);

class RootElement extends Component {
  constructor(props) {
    super(props);

    this.state = {
      ready: false,
    }
  }

  componentDidMount() {
    this.setState({
      ready: true,
    });
  }

  getContext = () => {
    const { ready } = this.state;
    return {
      ready,
    }
  }

  render() {
    const { children } = this.props;
    return (
      <AppContext.Provider value={this.getContext()}>
        <PageLoadManager>
          {children}
        </PageLoadManager>
      </AppContext.Provider>
    );
  }
}

export const wrapRootElement = ({ element }) => <RootElement>{element}</RootElement>;


/**
 * Scroll indicator
 */
export const onRouteUpdate = () => {
  const options = {
    color: '#000',
    height: '4px',
    // paths: [`**`],
    zIndex: 9999,
  };

  // Check current path with specified paths in options
  // const matchedPaths = multimatch(location.pathname, options.paths);
  // Return bool if paths match
  // const enableScroller = matchedPaths.length > 0;
  const enableScroller = true;

  // Create indicator container and append to document body
  const node = document.createElement(`div`);
  node.id = `scroll-indicator`;

  // check if viewport already has a scroll indicator
  const indicatorPresent = document.querySelector(`#${node.id}`);

  if (enableScroller) {
    if (!indicatorPresent) {
      document.body.appendChild(node);
      let scrolling = false;
      const indicator = document.getElementById(node.id);
      // Determine width of scroll indicator
      const getIndicatorPercentageWidth = (currentPos, totalScroll) => {
        return (currentPos / totalScroll) * 100;
      };
      // Find the total height of scrolling window
      const getScrollHeight = () => {
        // https://javascript.info/size-and-scroll-window#width-height-of-the-document
        return Math.max(
          document.body.scrollHeight,
          document.documentElement.scrollHeight,
          document.body.offsetHeight,
          document.documentElement.offsetHeight,
          document.body.clientHeight,
          document.documentElement.clientHeight
        );
      };
      // Add throttled listener to update on scroll
      window.addEventListener('scroll', () => {
        const currentPos = window.scrollY;
        const { innerHeight } = window;
        const scrollHeight = getScrollHeight();
        const scrollDistance = scrollHeight - innerHeight;

        if (!scrolling) {
          window.requestAnimationFrame(() => {
            const indicatorWidth = getIndicatorPercentageWidth(
              currentPos,
              scrollDistance
            );
            const { color, height, zIndex } = options;
            indicator.style.cssText = `
              background: ${color};
              height: ${height};
              left: 0;
              position: fixed;
              top: 0;
              width: ${indicatorWidth}%;
              z-index: ${zIndex};
            `;
            scrolling = false;
          });
          scrolling = true;
        }
      });
    }
  } else {
    // Try to assign scrollIndicator if it is already attached to the DOM
    const scrollIndicator = document.querySelector(
      '#scroll-indicator'
    );
    // If the indicator is already attached to the DOM, remove it
    if (scrollIndicator) {
      scrollIndicator.remove();
    }
  }
};