/**
 * PageHeader
 */

import React from 'react';
import get from 'lodash/get';
import { graphql } from 'gatsby';
import { ContentParser, Image, Link } from '@components';

const PageHeader = ({
  eyebrow,
  primary,
  secondary,
  intro,
  image,
  image_alt,
  primary_cta,
  secondary_cta
}) => {
  if (!primary && !secondary && !intro && !eyebrow && !image) {
    return null;
  }

  const hero = (
    <>
      { eyebrow && <ContentParser element="div" className="eyebrow fade-in-eyebrow" content={eyebrow} />}
      <h1 className="page__title page__title--hero">
        { primary && <ContentParser element="div" className="primary fade-in-title" content={primary} />}
        { secondary && <ContentParser element="div" className="secondary fade-in-subtitle" content={secondary} />}
      </h1>
      {intro && (
        <>
          <div className="spacer--s" />
          <ContentParser
            className="page__block--text fade-in-intro"
            element="div"
            content={intro}
          />
        </>
      )}
      {
        (primary_cta || secondary_cta) && (
          <>
            <div className="spacer--s" />
            <div className="page__block--button-group fade-in-page-header-buttons">
              {primary_cta.label && (
                <Link className="button--primary" href={primary_cta.url}>
                  {primary_cta.label}
                </Link>
              )}
              {secondary_cta.label && (
                <Link className="button" href={secondary_cta.url}>
                  {secondary_cta.label}
                </Link>
              )}
            </div>
          </>
        )
      }
    </>
  );

  /**
   * Split layout
   */
  if (image) {
    return (
      <div className="page__header">
        <div className="page__header__split-layout">
          <div className="page__header__split-layout__section page__header__split-layout__section--a">
            {hero}
          </div>
          <div className="page__header__split-layout__section page__header__split-layout__section--b fade-in-page-header-image">
            <div className="page__header-illustration">
              <Image
                alt={image_alt}
                // fluid={get(image, 'localFile.childImageSharp.fluid')}
                localFile={get(image, 'localFile')}
                className="lg"
                style={{ width: '100%'}}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }

  /**
   * Default page header
   */
  return (
    <div className="page__header">
      <div className="page__header__inner">
        {hero}
      </div>
    </div>
  );
}

export default PageHeader;

export const pageHeaderQuery = graphql`
  fragment PageHeader on wordpress__PAGEAcf {
    page_header_eyebrow
    page_header_primary_title
    page_header_secondary_title
    page_header_intro
    page_header_image_alt
    page_header_image {
      localFile {
        aspectRatio
        url
        childImageSharp {
          fluid(quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
    page_header_primary_cta {
      label
      url
    }
    page_header_secondary_cta {
      label
      url
    }
  }
`