/**
* Convert WP url to path
*/

const convertWpUrlToPath = url => {
  if (!url) {
    return null;
  }

  return url.replace(process.env.GATSBY_API_URL, '');
};

export default convertWpUrlToPath;