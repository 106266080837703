/**
 * Link
 */

import React from 'react';
import { Link } from 'gatsby';

const EnhancedLink = ({ href, children, ...rest }) => {
  let Element = 'a';

  const props = {
    ...rest,
  };
  // Render a regular link if the href contains "http" etc.
  if (href) {
    // Internal link
    if (href.includes('https://samhaeng.com')) {
      props.to = href.slice(href.indexOf('.com') + 4, href.length);
      Element = Link;
    } else if (href.includes('http') || href.includes('mailto:') || href.includes('tel:')) {
      props.href = href;
      props.rel = "noopener noreferrer";
      props.target = "_blank";
  
    // Render a react-router link
    } else if (typeof href === 'string') {
      props.to = href;
      Element = Link;
    }
  }

  return (
    <Element {...props}>
      {children}
    </Element>
  )
}

EnhancedLink.defaultProps = {
  href: '',
}

export default EnhancedLink;
