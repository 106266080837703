/**
 * Site Meta
 * 
 * Will render all meta tags, title etc.
 */

import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import Helmet from 'react-helmet';
import get from 'lodash/get';

const SiteMeta = ({ data }) => {
  const optionsData = useStaticQuery(graphql`
    query DefaultSEOoptions {
      wordpressSiteMetadata {
        name
      }
      wordpressAcfOptions {
        options {
          seo {
            title
            description
            image {
              localFile {
                childImageSharp {
                  fixed {
                    src
                  }
                }
              }
            }
          }
        }
      }
    }
  `)
  
  const defaultSettings = get(optionsData, 'wordpressAcfOptions.options.seo', {});
  const siteOptions = get(optionsData, 'wordpressSiteMetadata', {});
  const siteName = siteOptions.name;
  const path = get(data, 'path', '');
  const noRobots = get(data, 'noRobots') === 'true' || get(data, 'no_robots') || false;
  const permalink = `${process.env.GATSBY_SITE_URL}${path}`;
  const pageTitle = get(data, 'pageTitle', null);
  const title = get(data, 'title', null) || defaultSettings.title.replace('{title}', pageTitle);
  const description = get(data, 'description', '') || defaultSettings.description;
  const image = get(data, 'image.localFile.childImageSharp.fixed.src', null) || get(defaultSettings, 'image.localFile.childImageSharp.fixed.src', null);

  const renderTwitterTags = () => [
    <meta key="twitter:title" name="twitter:title" content={title} />,
    <meta key="twitter:description" name="twitter:description" content={description} />,
    <meta key="twitter:image" name="twitter:image" content={image} />,
    <meta key="twitter:site" name="twitter:site" content="@samhaeng_ux" />,
    <meta key="twitter:creator" name="twitter:creator" content="@samhaeng_ux" />
  ];

  const renderFacebookTags = () => [
    <meta key="og:type" property="og:type" content="article" />,
    <meta key="og:title" property="og:title" content={title} />,
    <meta key="og:description" property="og:description" content={description} />,
    <meta key="og:image" property="og:image" content={image} />,
    <meta key="og:url" property="og:url" content={permalink} />,
    <meta key="og:site_name" property="og:site_name" content={siteName} />,
  ];

  return (
    <Helmet>
      <title>
        {title}
      </title>
      { description && <meta name="description" content={description} /> }
      {noRobots && <meta name="robots" content="noindex, nofollow" />}
      {renderTwitterTags()}
      {renderFacebookTags()}
    </Helmet>
  );
}

export default SiteMeta;
