/**
 * Base Layout
 */

import React from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import { AppContext } from '@context';
import { SiteMeta } from './components';

const BaseLayout = ({ children, className, siteMeta, template, ...rest }) => (
  <AppContext.Consumer>
    {({ ready }) => (
      <>
        <SiteMeta data={siteMeta} />
        <div 
          className={classnames(
            'site', 
            className, 
            {'page-has-been-loaded': ready },
            {[`template-${template}`]: template },
          )}
          dir="ltr"
          {...rest}
        >
          {children}
        </div>
      </>
    )}
  </AppContext.Consumer>

  
  // <Fragment>
  //   <SiteMeta values={siteMeta} />
  //   <div {...rest} className={classnames(css.root, className)}>
  //     {children}
  //   </div>
  // </Fragment>
);

BaseLayout.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  siteMeta: PropTypes.object,
  template: PropTypes.string,
};

export default BaseLayout;
