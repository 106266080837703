/**
 * Image
 * @missing-prop-types
 */

import React from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import GatsbyImage from 'gatsby-image';
import css from './Image.module.css';

const Image = ({
  alt,
  className,
  fluid: fluidProp,
  fixed: fixedProp,
  localFile,
  imgStyle,
  ...rest
}) => {
  const { aspectRatio, url, childImageSharp } = localFile || {};
  const publicURL = url;

  if (childImageSharp || fluidProp || fixedProp) {
    const { fluid: childFluid, fixed: childFixed } = childImageSharp || {};
    const fixed = fixedProp || childFixed;
    const fluid = fluidProp || childFluid;
    if (fluid || fixed) {
      return (
        <GatsbyImage
          className={className}
          imgStyle={imgStyle}
          alt={alt}
          fixed={fixed}
          fluid={fluid}
          {...rest}
        />
      );
    }
  }

  if (publicURL) {
    const imageEl = <img src={publicURL} alt={alt} {...rest} />;

    if (aspectRatio) {
      return (
        <div
          style={{ paddingTop: `${aspectRatio * 100}%` }}
          className={classnames(css.aspectRatioContainer, className, 'image-aspect-ratio-container')}
        >
          {imageEl}
        </div>
      );
    }

    return imageEl;
  }

  return (
    <GatsbyImage 
      {...rest}
      // durationFadeIn={50000}
      // placeholderClassName={css.blur}
      // onLoad={() => setLoaded(true)} 
      className={classnames(css.root, className)}
    />
  );
};

Image.propTypes = {
  alt: PropTypes.string,
  localFile: PropTypes.object,
};

Image.defaultProps = {
  alt: '',
};

export default Image;
