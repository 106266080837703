/**
 * Page Layout
 */

import React from 'react';
import classnames from 'classnames';
import get from 'lodash/get';
import PropTypes from 'prop-types';
import BaseLayout from '../BaseLayout';
import { Header, Footer, PageHeader } from '../components';

const PageLayout = ({ children, pageHeader, siteMeta, template }) => (
  <BaseLayout siteMeta={siteMeta} template={template}>
    <Header />
    <main id="site__body">
      <div className={classnames('page', {'page-has-no-offset': get(pageHeader, 'image', false)})}>
        {pageHeader && <PageHeader {...pageHeader} />}
        <div className="page__body fade-in-page__body">
          <div className="page__body__inner">
            {children}
            <Footer />
          </div>
        </div>
      </div>
    </main>
  </BaseLayout>
);

PageLayout.propTypes = {
  children: PropTypes.node,
  pageHeader: PropTypes.object,
  siteMeta: PropTypes.object,
  template: PropTypes.node,
};

PageLayout.defaultProps = {
};

export default PageLayout;
