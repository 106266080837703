/**
 * Gif
 */

import React, { useState } from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import { useInView } from 'react-hook-inview';

const Gif = ({ alt, className, path, placeholder, ...rest }) => {
  const [loaded, setLoaded] = useState(false);
  const [ref, inView] = useInView({
    onEnter: () => setLoaded(true)
    // onLeave: (e) => e.target.pause(),
  });

  const storageUrl = process.env.GATSBY_STORAGE_URL;
  const url = `${storageUrl}${path}`;

  return (
    <img
      ref={ref}
      alt={alt}
      {...rest}
      className={
        classnames(
          className,
          'animated-on-scroll',
          {'animated-on-scroll--visible': inView}
        )
      }
      src={`${url}/full.gif`}
    />
  );
};

Gif.propTypes = {
  path: PropTypes.string,
  poster: PropTypes.string
};

export default Gif;
